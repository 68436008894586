<template>
  <div>

    <div 
      class="
        d-flex 
        justify-start 
        align-center" 
      style="
        height: 60px; 
        color: #d31145; 
        background-color: #fff;
        padding: 0 20px 0 20px;">

      <v-icon 
        color="#d31145" 
        @click="$router.push('/inventory')">
        mdi-arrow-left
      </v-icon>

      <span 
        style="
          padding-left: 15px;
          font-weight: 700">
        Kartu Stok
      </span>

    </div>
    
    <v-divider></v-divider>

    <div class="px-5 py-5">
      <v-row>

        <v-col cols="12" class="py-4">

          <div 
            class="
              font-weight-bold 
              text-title" 
            style="font-size: 16px">

            Pilih Produk

          </div>

          <v-text-field
            v-model="form.product_name"
            placeholder="Tuliskan disini"
            @click="openDialog()"
            required
            color="#d31145"
            maxlength="50"
            autocomplete="off"
            hide-details="auto"
            class="py-0 cursor-pointer"
            style="padding-top: 1px !important">
          </v-text-field>

          <div 
            style="
              display: flex;
              justify-content:space-between;
              padding-top:8px;">
              
            <div class="text-required">
              Wajib
            </div>

          </div>

        </v-col>

        <v-col cols="12" class="py-3">

          <div 
            class="
              font-weight-bold 
              text-title" 
            style="font-size: 16px">

            Pilih Tanggal Awal

          </div>

          <v-dialog
            ref="dialog_start_date"
            v-model="dialog.start_date"
            :return-value.sync="form.start_date"
            persistent
            width="290px">

            <template v-slot:activator="{ on, attrs }">

              <v-text-field
                v-model="StartDate"
                placeholder="Pilih Tanggal Awal"
                required
                color="#ACACAC"
                maxlength="50"
                autocomplete="off"
                hide-details="auto"
                v-bind="attrs"
                v-on="on"
                class="py-0"
                style="padding-top: 1px !important">
              </v-text-field>
              
            </template>

            <v-date-picker
              color="#d31145"
              v-model="form.start_date"
              scrollable>

              <v-spacer></v-spacer>

              <v-btn
                text
                color="#d31145"
                @click="dialog.start_date = false">
                Cancel
              </v-btn>

              <v-btn
                text
                color="#d31145"
                @click="saveStartDate()">
                OK
              </v-btn>

            </v-date-picker>

          </v-dialog>

          <div 
            style="
              display: flex;
              justify-content:space-between;
              padding-top:8px;">
              
            <div class="text-required">
              Wajib
            </div>

          </div>

        </v-col>

        <v-col cols="12" class="py-3">

          <div 
            class="
              font-weight-bold 
              text-title" 
            style="font-size: 16px">

            Pilih Tanggal Akhir

          </div>

          <v-dialog
            ref="dialog_end_date"
            v-model="dialog.end_date"
            :return-value.sync="form.end_date"
            persistent
            width="290px">

            <template v-slot:activator="{ on, attrs }">
              
              <v-text-field
                v-model="EndDate"
                placeholder="Pilih Tanggal Akhir"
                required
                color="#ACACAC"
                maxlength="50"
                autocomplete="off"
                hide-details="auto"
                v-bind="attrs"
                v-on="on"
                class="py-0 cursor-pointer"
                style="padding-top: 1px !important">
              </v-text-field>

            </template>

            <v-date-picker
              color="#d31145"
              :max="DatePeriodMAX"
              :min="form.start_date"
              v-model="form.end_date"
              scrollable>

              <v-spacer></v-spacer>

              <v-btn
                text
                color="#d31145"
                @click="dialog.end_date = false">
                Cancel
              </v-btn>

              <v-btn
                text
                color="#d31145"
                @click="$refs.dialog_end_date.save(form.end_date)">
                OK
              </v-btn>

            </v-date-picker>

          </v-dialog>

          <div 
            style="
              display: flex;
              justify-content:space-between;
              padding-top:8px;">

            <div class="text-required">
              Wajib
            </div>

          </div>

        </v-col>

        <v-col cols="12" class="py-3">

          <v-alert
            dense
            text
            icon="mdi-alert-outline"
            color="#FF9800">
            Periode maksimal 3 bulan
          </v-alert>
          
        </v-col>

      </v-row>
    </div>

    <v-btn 
      class="float white--text text-capitalize" 
      :loading="process.run" 
      @click="toValidation()" 
      width="150" 
      rounded 
      color="#D31145">

      <v-icon 
        color="#fff" 
        class="my-float" 
        left>
        mdi-send
      </v-icon>

      Sumbit

    </v-btn>


    <v-dialog 
      v-model="dialog.selected"
      transition="dialog-bottom-transition"
      scrollable
      persistent
      fullscreen>
      <v-card>

        <v-card-title
          style="
            height: 60px;
            color: #d31145;
            background-color: #fff;
            padding: 0 20px 0 20px;">

          <v-icon 
            @click="dialog.selected = false" 
            color="#d31145">
            mdi-arrow-left
          </v-icon>

          <span 
            style="
              padding-left: 15px;
              font-weight: 700;
              font-size: 20px">
            List Produk
          </span>

        </v-card-title>
        
        <v-divider></v-divider>

        <v-card-text 
          class="px-0 py-0" 
          style="height:300px">

          <div 
            v-if="product_list.length > 0 || searching != ''"
            class="
              d-flex 
              justify-space-around 
              align-center 
              pa-4" 
            style="
              background-color: #fff;
              height:60px">

            <v-text-field
              dense
              v-model="searching"
              :hide-details="true"
              color="#ACACAC"
              filled
              rounded
              placeholder="Search..."
              elevation="0">
              <template v-slot:prepend-inner>
                <v-icon 
                  color="#ACACAC">
                  mdi-magnify
                </v-icon>
              </template>

              <template v-slot:append>
                <v-icon 
                  color="#FF2353"  
                  v-show="searching != ''"
                  @click="
                    searching = '';
                    getProduct()">
                  mdi-close-circle
                </v-icon>
              </template>
            </v-text-field>

          </div>

          <v-card flat v-if="product_list.length < 1 && searching == '' && !process.run">

            <v-card-text 
              style="padding: 20px 20px">
              <div 
                style="
                  font-weight: 700;
                  font-size:16px;
                  padding-bottom: 5px">
                Anda belum menambahkan Produk
              </div>

              <div 
                style="
                font-weight: normal;
                font-size: 14px;
                color:#ACACAC;
                line-height: 1.5;
                padding-bottom: 5px">

                Belum ada produk atau layanan pada toko ini. 

                <br>

                Anda bisa menambahkan dengan menekan tombol di bawah.

              </div>

              <div 
                style="padding-top:20px">

                <v-btn 
                  to="/master/product/form" 
                  rounded 
                  depressed 
                  color="#d31145" 
                  elevation="0" 
                  class="
                    white--text 
                    text-capitalize">

                  <v-icon left>
                    mdi-plus
                  </v-icon>

                  Produk

                </v-btn>

              </div>

            </v-card-text>

          </v-card>

          <v-card
            v-else
            flat
            class="mx-auto">

            <div v-if="product_list.length < 1 && searching == '' && process.run">
              <v-row class="py-2">
                <v-col cols="12" v-for="item in 15" :key="item">
                  <v-skeleton-loader
                    class="rounded-lg"
                    type="list-item-avatar-two-line">
                  </v-skeleton-loader>
                </v-col>
              </v-row>
            </div>

            <v-card flat v-else-if="product_list.length < 1 && searching != ''" class="px-0">

              <v-card-text
                class="d-flex align-center"
                style="
                  height: calc(100vh - 100px); 
                  background: #f9f9f9; ">
                  <v-img
                    class="mx-auto"
                    max-width="200"
                    src="https://sodapos.com/media/2021/12/image/no-result-1638951068.png">
                  </v-img>
              </v-card-text>

            </v-card>

            <v-list class="py-0" v-else>

              <v-list-item-group>

                <v-list-item
                  v-for="(item, i) in product_list"
                  :key="i"
                  @click="addData(item, i)">

                  <v-list-item-avatar tile class="rounded-lg">
                    <v-img
                      width="50"
                      height="50"
                      :src="item.image_url">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            color="#d31145">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>

                    <v-list-item-title class="text-title" style="font-weight: 600" v-text="item.name"></v-list-item-title>
                    <v-list-item-subtitle class="text-red" style="font-size: 13px !important"> {{ item.sku }} </v-list-item-subtitle>

                  </v-list-item-content>

                  <v-list-item-icon>

                    <v-icon>
                      mdi-chevron-right
                    </v-icon>
                  
                  </v-list-item-icon>
                
                </v-list-item>
              
              </v-list-item-group>
            
            </v-list>
          
          </v-card>
        
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.detail"
      transition="dialog-bottom-transition"
      scrollable
      persistent
      fullscreen>
      <v-card flat>

        <v-card-title 
          style="
            height: 60px;
            color: #d31145;
            background-color: #fff;
            padding: 0 20px 0 20px;">

          <v-icon 
            @click="dialog.detail = false" 
            color="#d31145">

            mdi-arrow-left

          </v-icon>

          <span 
            style="
              padding-left: 15px;
              font-weight: 700;
              font-size: 20px">
            Kartu Stok
          </span>

        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="py-0 px-0">

          <v-tabs fixed-tabs v-model="tab" slider-size="3" color="#d31145">

            <v-tab 
              class="text-capitalize">
              Informasi Produk
            </v-tab>

            <v-tab 
              class="text-capitalize">
              Riwayat
            </v-tab>

          </v-tabs>

          <v-tabs-items v-model="tab">

            <v-tab-item class="px-4">
              
              <!-- <div class="d-flex justify-space-around align-center pt-5">
                <div class="text-capitalize text-title font-weight-bold" style="font-size: 18px">
                  29 Desember 2021
                </div>
                <div style="border: 1px solid #FA91AD57;height:25px;"></div>
                <div class="font-weight-bold" style="color: #FA91AD;font-size: 18px">
                  ST-LOG-211129-00005
                </div>
              </div> -->

              <div style="width: 100%">

                <div 
                  class="
                    text-capitalize 
                    text-red 
                    text-h6 
                    mt-10" >

                  Informasi Produk
                </div>

                <v-simple-table class="py-5" dense>

                  <template>

                    <tbody>

                      <tr>
                        <th class="text-start px-0" style="font-weight: 400;width: 200px">Kode Produk</th>
                        <td class="text-center text-second px-0" style="width: 10px">:</td>
                        <td class="text-start text-title pl-5 pr-0" style="flex: 1">
                          {{ summary.product.sku }}
                        </td>
                      </tr>

                      <tr>
                        <th class="text-start px-0" style="font-weight: 400;width: 200px">Kategori Produk</th>
                        <td class="text-center text-second px-0" style="width: 10px">:</td>
                        <td class="text-start text-title pl-5 pr-0 text-capitalize" style="flex: 1">
                          {{ summary.product.category_name }}
                        </td>
                      </tr>

                      <tr>
                        <th class="text-start px-0" style="font-weight: 400;width: 200px">Harga Produk</th>
                        <td class="text-center text-second px-0" style="width: 10px">:</td>
                        <td class="text-start text-title pl-5 pr-0" style="flex: 1">
                          {{ summary.product.purchase_price | price }}
                        </td>
                      </tr>

                      <tr>
                        <th class="text-start px-0" style="font-weight: 400;width: 200px">Satuan Produk</th>
                        <td class="text-center text-second px-0" style="width: 10px">:</td>
                        <td class="text-start text-title pl-5 pr-0 text-capitalize" style="flex: 1">
                          {{ summary.product.unit }}
                        </td>

                      </tr>

                    </tbody>

                  </template>

                </v-simple-table>

              </div>

              <div style="width: 100%">
                <div 
                  class="
                    text-capitalize 
                    text-red 
                    text-h6 
                    mt-6" >

                  Ringkasan Persediaan

                </div>

                <v-simple-table class="py-5" dense>
                  
                  <template>

                    <tbody>

                      <tr>
                        <th class="text-start px-0" style="font-weight: 400;width: 200px">Total Masuk</th>
                        <td class="text-center text-second px-0" style="width: 10px">:</td>
                        <td class="text-start text-title pl-5 pr-0" style="flex: 1">
                          {{ summary.stock.total_in | num_format }}
                        </td>
                      </tr>

                      <tr>
                        <th class="text-start px-0" style="font-weight: 400;width: 200px">Total Keluar</th>
                        <td class="text-center text-second px-0" style="width: 10px">:</td>
                        <td class="text-start text-title pl-5 pr-0" style="flex: 1">
                          {{ summary.stock.total_out | num_format }}
                        </td>
                      </tr>

                      <tr>
                        <th class="text-start px-0" style="font-weight: 400;width: 200px">Stok Sebelum</th>
                        <td class="text-center text-second px-0" style="width: 10px">:</td>
                        <td class="text-start text-title pl-5 pr-0" style="flex: 1">
                          {{ summary.stock.previous_balance | num_format }}
                        </td>
                      </tr>

                      <tr>
                        <th class="text-start px-0" style="font-weight: 400;width: 200px">Stok Sekarang</th>
                        <td class="text-center text-second px-0" style="width: 10px">:</td>
                        <td class="text-start text-title pl-5 pr-0" style="flex: 1">
                          {{ summary.stock.total_balance | num_format }}
                        </td>
                      </tr>

                    </tbody>

                  </template>

                </v-simple-table>
                
              </div>
              

            </v-tab-item>
            
            <v-tab-item>

              <v-card flat v-if="summary.history.length < 1">

                <v-card-text
                  class="d-flex align-center"
                  style="
                    height: calc(100vh - 200px); 
                    background: #fff; ">

                    <v-img
                      class="mx-auto"
                      max-width="200"
                      src="https://sodapos.com/media/2021/12/image/no-result-1638951068.png">
                    </v-img>

                </v-card-text>

              </v-card>
              
              <v-list dense v-else>

                <template v-for="(item, i) in summary.history">

                <v-list-item :key="item.log_id">

                  <v-list-item-content>

                    <v-list-item-title 
                      class="
                        d-flex 
                        justify-space-between 
                        align-center 
                        pt-5">

                      <!-- <div class="text-capitalize text-title font-weight-bold" style="font-size: 18px">
                        {{ item.date | dateFull }}
                      </div> -->
                      <!-- <div style="border: 1px solid #FA91AD57;height:25px;"></div>
                      <div class="font-weight-bold" style="color: #FA91AD;font-size: 18px">
                        {{ item.code }}
                      </div> -->

                    </v-list-item-title>

                    <div class="py-2">

                      <div 
                        class="font-weight-bold mb-2" 
                        style="
                          color: #FA91AD;
                          font-size: 18px">

                        {{ item.code }}

                      </div>

                      <v-list-item-subtitle 
                        class="mt-1 mb-4 px-0" 
                        style="color: #989898"> 

                        Catatan : {{ item.note }} 

                      </v-list-item-subtitle>

                      <v-list-item-subtitle 
                        style="font-size: 14px;">

                        <div class="d-flex align-center">

                          <div class="text-start" style="flex: 1">

                            <div 
                              class="
                                text-capitalize 
                                text-title 
                                mb-1">

                              Stok Masuk : {{ item.stock_in | num_format }}
                            </div>

                            <div class="text-title">

                              Stok Keluar : {{ item.stock_out | num_format }}
                            </div>
                          </div>

                          <div 
                            style="
                              border: 1px solid #FA91AD57;
                              height:35px;" 
                            class="pl-0">
                          </div>

                          <div 
                            class="
                              text-start 
                              pl-7 
                              text-title" 
                            style="flex: 1">

                            <div class="text-title mb-1">

                             Stok Sebelum : {{ item.stock_prev_balance | num_format }}
                            </div>

                            <div class="text-title">

                              Stok Sekarang : {{ item.stock_balance | num_format }}
                            </div>

                          </div>

                        </div>

                      </v-list-item-subtitle>

                    </div>

                    <v-list-item-subtitle 
                      class="
                        text-end 
                        pt-0 
                        font-italic 
                        mt-3"
                      style="
                        color: #ACACAC;
                        font-size: 14px">

                      {{ item.date | dateFull }}

                    </v-list-item-subtitle>
                    
                  </v-list-item-content>

                </v-list-item>

                <v-divider :key="i" class="mx-auto"></v-divider>

              </template>

            </v-list>

            </v-tab-item>

          </v-tabs-items>
          
        </v-card-text>

      </v-card>

    </v-dialog>

  </div>
</template>

<script>

import moment from "moment"

export default {
  data: () => ({
    tab: null,
    form: {
      product_id: "",
      product_name: "",
      start_date: new Date().toISOString().toString().substr(0, 10),
      end_date: new Date().toISOString().toString().substr(0, 10),
    },
    searching: "",
    product_list: [],
    summary: {
      product: {},
      stock: {},
      history: [],
    },
    dialog: { 
      selected: false,
      start_date: false,
      end_date: false,
      detail: false,
    },
    process: {
      run: false,
    }
  }),
  components: {

  },
  watch: {
    searching: _.debounce(function(newVal) {
      this.searching = newVal;

      this.getProduct();
    }, 500),
  },
  computed: {
    StartDate() {
      return moment(this.form.start_date).format('DD MMMM YYYY')
    },
    EndDate() {
      return moment(this.form.end_date).format('DD MMMM YYYY')
    },
    DatePeriodMAX() {
      var day = new Date(this.form.start_date);
      // console.log(day.toLocaleDateString());
      day.setMonth(day.getMonth() + 3);
      // console.log(day.toISOString().substr(0, 10));
      let dateLength = day.toISOString().substr(0, 10)
      console.log(dateLength);
      return dateLength
    },
  },
  created() {

  },
  mounted(){

  },
  methods: {
    saveStartDate(){
      this.$refs.dialog_start_date.save(this.form.start_date);
      this.form.end_date = this.form.start_date
    },
    async openDialog(){
      this.dialog.selected = true;
      await this.getProduct()
    },
    clearData(){
      this.dialog.detail = false;
      this.form = {
        product_id: "",
        product_name: "",
        start_date: new Date().toISOString().toString().substr(0, 10),
        end_date: new Date().toISOString().toString().substr(0, 10)
      }
    },
    addData(item,i){
      this.form.product_id = item.id; 
      this.form.product_name = item.name
      this.dialog.selected = false;
    },
    toValidation(){
      if (!this.form.product_id) {
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Silahkan Pilih Produk Terlebih dahulu"
      }else {
        this.submit()
      }
    },
    async getProduct(){
      this.process.run = true
      let params = {
        limit: 10000,
        sort: "",
        dir: "",
        page: 1,
        search: this.searching,
      }
      
      let res = await this.$get(`pos/product`, params)

      if (res.status == 200) {
        this.product_list = res.results.data
        this.dialog.selected = true
        this.process.run = false
      }
    },
    async submit(){
      this.$store.state.overlay.state = true;
      let params = {
        id: parseInt(this.form.product_id),
        startDate: this.form.start_date,
        endDate: this.form.end_date
      }

      let res = await this.$get(`pos/stock-card/summary`, params)

      if (res.status == 200) {
        this.summary.stock = res.results.stock
        this.summary.product = res.results.product
        this.getHistory()
      }
    },
    async getHistory(){
      this.$store.state.overlay.state = true
      let data = {
        id: this.form.product_id,
        startDate: this.form.start_date,
        endDate: this.form.end_date,
        limit: 1000
      }

      let res = await this.$get(`pos/stock-card`, data)

      if (res.status == 200) {
        this.summary.history = res.results.data
        this.$store.state.overlay.state = false
        this.dialog.detail = true
      }
    },
  }
}
</script>

<style>

</style>